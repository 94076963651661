<script setup lang="ts">
import { permissionCreate } from "~/constants/permission"
type WidthType = 'small' | 'medium' | 'large' | 'xlarge';

export interface ISelect {
  label: string
  modelValue: string | number | null | undefined
  rules?: any[]
  options: any[]
  optionValue?: string
  optionLabel?: string
  required?: boolean
  clearable?: boolean
  useInput?: boolean
  dark?: boolean
  autofocus?: boolean
  multiple?: boolean
  modal?: boolean
  module?: string
  class?: string
  fullscreen?: boolean
  hasOptionsSlot?: boolean
  disable?: boolean
  hideBottomSpace?: boolean
  addMarginBottom?: boolean
  width: WidthType
}

const props = withDefaults(defineProps<ISelect>(), {
  optionValue: 'id',
  optionLabel: 'name',
  clearable: true,
  useInput: true,
  multiple: false,
  modal: false,
  hasOptionsSlot: false,
  disable: false,
  fullscreen: false,
  width: 'xlarge',
  hideBottomSpace: true,
  addMarginBottom: true
})

const widthComponent: Record<WidthType, string> = {
  'small': 'width: 110px',
  'medium': 'width: 150px',
  'large': 'width: 300px',
  'xlarge': ''
}

let modalOpen = ref(false)

const selectRef = ref()

let opts = toRef(props, 'options'), options = ref<any[]>()
const emit = defineEmits(['update:modelValue', 'change'])

function filterFn(val: string, update: (arg0: () => void) => void, abort: any): void {
  update(() => {
    if (val === '') options.value = props.options
      const needle = val.toLowerCase()
      options.value = props.options.filter(v => v[props.optionLabel] ? v[props.optionLabel ?? ''].toLowerCase().indexOf(needle) > -1 : v.toLowerCase().indexOf(needle) > -1)
  })
}

function onUpdate(event: any) {
  emit('update:modelValue', event)
  emit('change', event)
}

defineExpose({
  openModal
})

function openModal() {
  modalOpen.value = !modalOpen.value
  if (!modalOpen.value) {
    options = ref<any[]>()
    opts = toRef(props, 'options')
  } else {
    selectRef.value.hidePopup()
  }
}
</script>

<template>
  <q-select ref="selectRef" :req="required ? 1 : 0" :class="`ma-0 ${props.class} ${addMarginBottom && 'mb-4'}`" filled
    dense :clearable="clearable" :rules="rules" :modelValue="modelValue" :option-value="optionValue"
    :option-label="optionLabel" :dark="dark ? true : $darkMode()" @update:modelValue="onUpdate" :use-input="useInput"
    hide-selected fill-input input-debounce="0" :options="options ? options : opts" :label="label" @filter="filterFn"
    :autofocus="autofocus" :multiple="multiple" :disable="disable" emit-value map-options
    :hide-bottom-space="hideBottomSpace" :style="widthComponent[width]">
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey">
          {{ $getTranslation('shared.labels.notFound') }}
        </q-item-section>
      </q-item>
    </template>
    <template v-if="hasOptionsSlot" v-slot:option="scope">
      <q-item v-bind="scope.itemProps">
        <q-item-section>
          <q-item-label>{{ scope.opt.name }}</q-item-label>
          <q-item-label caption>{{ scope.opt.status }}</q-item-label>
        </q-item-section>
      </q-item>
    </template>
    <template v-if="modal && $hasAccess(`${permissionCreate} - ${module}`)" v-slot:append>
      <q-btn round dense flat icon="add" @click.stop.prevent @click="openModal()" />
    </template>
  </q-select>

  <!-- Form Dialog -->
  <q-dialog v-model="modalOpen" :maximized="fullscreen" transition-show="slide-up" transition-hide="slide-down">
    <q-card :dark="$darkMode()">
      <q-bar>
        <q-space />
        <q-btn dense flat icon="close" v-close-popup>
          <q-tooltip class="bg-white text-primary">{{ $getTranslation('shared.buttons.close') }}</q-tooltip>
        </q-btn>
      </q-bar>
      <q-card-section class="row items-center">
        <slot name="modal-form" />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>